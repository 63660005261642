import IsExernalLink from './isExternalLink';
import GetScrollbarWidth from './getScrollBarWidth';
import TruncateSymbolsToNumber from './truncateSymbolsToNumber';
import FloorTo from './floorTo';
import FormatMediaURL from './formatMediaUrl';
import timeByUTCDateString from './timeByUTCDateString';

export const isExternalLink = IsExernalLink;
export const getScrollbarWidth = GetScrollbarWidth;
export const truncateSymbolsToNumber = TruncateSymbolsToNumber;
export const formatMediaUrl = FormatMediaURL;
export const floorTo = FloorTo;

export default {
  timeByUTCDateString,
  isExternalLink: IsExernalLink,
  getScrollbarWidth: GetScrollbarWidth,
  truncateSymbolsToNumber: TruncateSymbolsToNumber,
  floorTo: FloorTo,
  formatMediaUrl: FormatMediaURL,
};
