import { PM, PMLive, SHOP_EVENTS } from 'vimmi-web-utils/cjs/postMessage';
import { screenMode } from 'vimmi-web-utils/cjs/screenMode';
import PopupService from '@/service/popup';
import WishlistService from '@/store/wishlist';
import { mapGetters, mapState } from 'vuex';
import { SET_MINIMAZE } from './events.const';
import { amsClient } from '@/service/ams';

export const EmbeddedMode = {
  data() {
    return {
      screenMode: screenMode(),
      hasFloatMode: false,
      canMoveFloating: false,
      offset: null,
      isReady: false,
      isReadyPM: false,
      eventTrigger: false,
    };
  },
  computed: {
    ...mapState('popup', ['floatingMode', 'screenType']),
    ...mapGetters({
      getVideosList: 'wishlist/getVideosList',
      miniPlayerFromProduct: 'popup/miniPlayerFromProduct',
    }),
    isMiniatureIframe() {
      return this.screenType === 'miniature';
    },
    floatPlayerRef() {
      return this.floatingMode === 'vod'
        ? this.$floatingVodRef
        : this.$floatingLiveRef;
    },
    currentLive() {
      return this.$store.getters['dataAreasLive/currentLive'];
    },
    currenSecondLive() {
      return this.$store.getters['dataAreasLive/currenSecondLive'];
    },
    isFirstEventComponentFloating() {
      return this.$store.getters['player/isFirstEventComponentFloating'];
    },
    isSecondEventComponentFloating() {
      return this.$store.getters['player/isSecondEventComponentFloating'];
    },
    pageForCurrentLive() {
      return this.$store.getters['player/pageForCurrentLive'];
    },
    pageForSecondLive() {
      return this.$store.getters['player/pageForSecondLive'];
    },
    playerCoords() {
      return this.$store.getters['player/playerCoords'];
    },
    isLiveData() {
      return this.$store.getters['player/isLiveData'];
    },
    isMutued() {
      return this.$store.getters['player/muted'];
    },
  },
  watch: {
    eventTrigger(value) {
      if (value) {
        this.$bus.$on('handleCloseFloatPlayer', this.handleCloseFloatMode);
        this.eventTrigger = false;
      }
    },
  },
  inject: ['toggleReadyLive'],
  created() {
    this.destroyPMEvents();
    this.initPM();
    this.mountBusEvents();
    window.parent.addEventListener('orientationchange', this.onOriChanged);
  },
  beforeDestroy() {
    window.parent.removeEventListener('orientationchange', this.onOriChanged);
    this.destroyBusEvents();
    // this.destroyPMEvents();
  },
  methods: {
    initPM() {
      /** need to handle clicks on the close button inside the player  */
      PM.inst().on(SHOP_EVENTS.OPEN_PRODUCT_IFRAME, this.handleOpenProductIframe);
      PM.inst().on('IS_MINIATURE', this.handleCheckMiniature);
      PM.inst().on('TOGGLE_LIKE', this.handleLike);
      PMLive.inst().on('MINIMAZE_LIVE', this.handleMinimazeLive);
      PM.inst().on('IS_READY', this.handleCheckIsReady);
      PM.inst().on('TOGGLE_MUTE', this.handleMute);
    },
    mountBusEvents() {
      this.$bus.$on('setMiniplayer', this.handleRoutingFloating);
      this.$bus.$on('handleCloseFloatPlayer', this.handleCloseFloatMode);
      this.$bus.$on('setVodFLoating', this.handleFloatVodPlayer);
      this.$bus.$on('setLiveFLoating', this.handleLiveFloating);
      this.$bus.$on('clearLiveFloating', this.clearStoreForLivePlayer);
      this.$bus.$on('handleCloseFloatingPMLive', this.handleCloseFloatingPMLive);
    },
    destroyBusEvents() {
      // this.$bus.$off('setMiniplayer');
      // this.$bus.$off('handleCloseFloatPlayer');
      this.$bus.$off('setVodFLoating');
      this.$bus.$off('setLiveFLoating');
    },
    destroyPMEvents() {
      PM.inst().off(SHOP_EVENTS.OPEN_PRODUCT_IFRAME, this.handleOpenProductIframe);
      PM.inst().off('IS_MINIATURE', this.handleCheckMiniature);
      PM.inst().off('TOGGLE_LIKE', this.handleLike);
      PM.inst().off('MINIMAZE_LIVE', this.handleMinimazeLive);
      PM.inst().off('IS_READY', this.handleCheckIsReady);
      PM.inst().off('TOGGLE_MUTE', this.handleMute);
    },
    handleOpenProductIframe(data = {}) {
      console.log('handleOpenProductIframe', 'embedded-mode.js');
      const source = data.ref?.source === 'vod' ? 'vod' : 'live';
      if (data.item) {
        this.setProductPopup(data);
      } else if (data.event && data.event === SET_MINIMAZE) {
        document.documentElement.style.overflow = 'visible';
      }
      if (data.item || data.event === SET_MINIMAZE) {
        this.setFloatModePlayer(source);
        this.toggleFloatModePlayer(source);
      }
      if (data.ref.isStream) {
        this.$bus.$emit('closeMiniVod');
      }
      // this.$store.commit('player/SET_IS_FROM_VIDEO', true);
    },
    handleCheckMiniature() {
      if (this.isMiniatureIframe) {
        PopupService.setScreenType('miniature');
      }
    },
    async handleLike(data) {
      if (Object.keys(this.getVideosList).includes(data.id)) {
        await WishlistService.removeFromVideosWishlist(data.id);
      } else {
        await WishlistService.addToVideoWishlist(data.id);
      }
      PM.inst().send({
        target: this.getCurrentFrame(`${this.eventIdentifier}_video_player`),
        type: 'FINISH_LIKE',
        data: {
          isWishList: Object.keys(this.getVideosList).includes(data.id),
        },
      });
    },
    handleMute(data) {
      this.$store.commit('player/SET_MUTED', data.muted);
    },
    handleMinimazeLive() {
      this.handleCloseFloatMode();
      this.$bus.$emit('closeMiniVod');
    },
    handleCheckIsReady(data) {
      if (data.isVod) {
        if (!this.isGuest && !this.isReady) {
          amsClient.callAms(`/stk/recent/?d=web&pk=${data.item.id}`, {
            method: 'post',
          });
        }
        this.toggleReadyLive(true);
      }
      this.isReady = true;
      if (this.isMiniatureIframe) {
        this.$store.commit('popup/setLoadingIframe', false);
        document.documentElement.style.overflow = 'visible';
        PM.inst().send({
          target: this.getCurrentFrame(
            `${this.eventIdentifier}_video_player`,
          ),
          type: 'TARGET_MINIATURE',
        });
        if (data.isVod && this.playerCoords) {
          const vodPalayer = document.querySelector('.shop_screen-miniature');
          vodPalayer.style.top = `${this.playerCoords.y}px`;
          vodPalayer.style.left = `${this.playerCoords.x}px`;
        }
      }
      if (this.pageForCurrentLive || this.pageForSecondLive) {
        this.$store.commit('dataAreasLive/setCurrenLive', this.isLiveData);
        const timeout = setTimeout(() => {
          this.$bus.$emit('setStyleForLive');
          clearTimeout(timeout);
        }, 2000);
      }
      if (this.isMutued) {
        PM.inst().send({
          target: this.getCurrentFrame(`${this.eventIdentifier}_video_player`),
          type: 'TOGGLE_MUTE',
          data: {
            muted: this.isMutued,
          },
        });
      }
    },
    handleCloseFloatMode(id = null) {
      this.$bus.$off('handleCloseFloatPlayer', this.handleCloseFloatMode);
      this.eventTrigger = true;
      this.floatPlayerRef?.removeAttribute('style');
      this.hasFloatMode = false;
      PopupService.setFloatingMode(null);
      PopupService.setScreenType('full');
      if (!this.isMiniatureIframe || this.miniPlayerFromProduct) {
        this.$store.commit('popup/setMiniPlayerFromProduct', false);
        PM.inst().send({
          target: this.getCurrentFrame(`${id || this.eventIdentifier}_video_player`),
          type: SHOP_EVENTS.SWITCH_MODE,
          data: {
            mode: 'l',
          },
        });
      }
    },
    toggleFloatModePlayer(source) {
      if (source === 'vod') {
        return;
      }
      if (this.currentLive && !this.currenSecondLive && !this.isFirstEventComponentFloating) {
        this.handleSetFirstEventComponentFloating();
        return;
      }
      if (this.currenSecondLive && !this.isSecondEventComponentFloating) {
        this.handleSetSecondEventComponentFloating();
        this.$store.commit('dataAreasLive/setCurrenLive', null);
      }
    },
    setFloatModePlayer(source = 'vod') {
      PopupService.setScreenType('miniature');
      PopupService.setFloatingMode(source);
      this.hasFloatMode = true;
    },
    handleFloatVodPlayer() {
      this.$store.commit('dataAreasLive/setPlayingLive', false);
      PopupService.setFloatingMode('vod');
    },
    handleLiveFloating(source = 'live') {
      if (source === 'vod') {
        this.clearStoreForLivePlayer();
        return;
      }
      if (this.currentLive && !this.currenSecondLive) {
        this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
          name: 'isFirstEventComponentFloating',
          value: true,
        });
        return;
      }
      if (this.currenSecondLive && this.currentLive) {
        if (this.isSecondEventComponentFloating) {
          this.$store.commit('dataAreasLive/setCurrenSecondLive', null);
          this.handleSetFirstEventComponentFloating();
          return;
        }
        if (this.isFirstEventComponentFloating) {
          this.$store.commit('dataAreasLive/setCurrenLive', null);
          this.handleSetSecondEventComponentFloating();
        }
      }
    },
    clearStoreForLivePlayer() {
      this.$store.commit('dataAreasLive/setCurrenLive', null);
      this.$store.commit('dataAreasLive/setCurrenSecondLive', null);
      this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');
    },
    handleSetFirstEventComponentFloating() {
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isFirstEventComponentFloating',
        value: true,
      });
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isSecondEventComponentFloating',
        value: false,
      });
    },
    handleSetSecondEventComponentFloating() {
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isFirstEventComponentFloating',
        value: false,
      });
      this.$store.commit('player/SET_EVENT_COMPONENT_STATE', {
        name: 'isSecondEventComponentFloating',
        value: true,
      });
    },
    handleRoutingFloating() {
      PM.inst().send({
        target: this.getCurrentFrame(`${this.eventIdentifier}_video_player`),
        type: 'IS_ROUTING',
      });
    },
    handleCloseFloatingPMLive() {
      PMLive.inst().send({
        target: this.getCurrentFrame(`${this.eventIdentifier}_video_player`),
        type: SHOP_EVENTS.CLOSE_FLOATING,
      });
      document.documentElement.style.overflow = 'visible';
    },
    onOriChanged: () => {
      setTimeout(() => this.screenMode = screenMode(), 200);
    },
    setProductPopup(data) {
      data.item.link = data.link;
      data.item.ref = data.ref;
      this.$bus.$emit('toggleProductPopup', { item: data.item });
      this.$store.commit('popup/setMiniPlayerFromProduct', true);
    },
  },
};
