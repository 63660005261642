<template>
  <div
    class="container-fluid bg-block py-3 px-3 px-lg-4"
    :class="{'d-flex': !isNewLiveDesign}"
  >
    <template v-if="isNewLiveDesign">
      <div class="row mb-3">
        <div class="col-4 col-sm-3 col-md-2 live-text-poster">
          <responsive-image
            class="live-text-image-main"
            aspect-ratio="1x1"
            :src="iconChannel"
          />
        </div>
        <div class="col-8 col-sm-9 col-md-10">
          <h2 class="live-text-text mt-1">
            {{ titleChannel }}
          </h2>
          <div class="user-action-block">
            <subscription-label
              v-if="isSubscribeBtn"
              is-btn
            />
            <button
              v-if="isShowFavoriteBtn"
              class="btn btn-circle-icon btn-favorite "
              :class="{'active': isFavorite}"
              @click="toggleFavorite"
            >
              <i
                class="fa-heart"
                :class="{'fas': isFavorite, 'far': !isFavorite}"
              />
            </button>
            <share-btn :item="channel"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-sm-3 col-md-2 text-center live-text-time">
          <span class="text-muted text-nowrap">{{ timeEvent }}</span>
        </div>
        <div class="col-8 col-sm-9 col-md-10">
          <h3
            class="d-block live-text-text mb-0 font-weight-normal"
          >
            {{ titleEvent }}
          </h3>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="live-text-poster"
      >
        <responsive-image
          class="live-text-image-main"
          aspect-ratio="1x1"
          img-class="live-text-image"
          :src="iconChannel"
        />
        <span
          class="text-muted text-nowrap live-text-time d-block d-lg-none"
        >{{ timeEvent }}</span>
      </div>
      <div
        class="d-flex flex-column flex-grow-1 align-items-start justify-content-between one-line-truncate-flex-fix ml-4"
      >
        <!-- <span class="channel-item-title text-truncate">{{ channelTitle }}</span> -->
        <h2 class="d-block live-text-text text-truncate mw-100 mt-1">
          {{ titleChannel }}
          <!-- <div class="no-wrap mt-2 d-inline-block">
          <button
            v-if="isAuth"
            class="btn-favorite"
            :class="{'active': isFavorite}"
            @click="toggleFavorite"
          >
            <i
              class="fa-heart"
              :class="{'fas': isFavorite, 'far': !isFavorite}"
            />
          </button>
          <share-btn :item="channel"/>
          </div>-->
        </h2>
        <div
          class="d-flex flex-row justify-content-between align-content-center mw-100 w-100 mb-2"
        >
          <div class="d-flex live-text-text one-line-truncate-flex-fix">
            <h3
              class="d-block live-text-text text-truncate mw-100 mb-0 font-weight-normal"
            >
              {{ titleEvent }}
            </h3>
          </div>
          <span
            class="text-muted text-nowrap live-text-time d-none d-lg-block"
          >{{ timeEvent }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Config from 'src/service/config';
  import ResponsiveImage from 'src/components/reponsive-image.vue';
  import formatDate from 'src/filters/formatDate.js';
  import { AuthVariableMixin, FavoriteMixin } from 'src/plugins/mixin';
  import ShareBtn from 'src/components/share-btn/share-btn';
  import SubscriptionLabel from 'src/components/subscription-label';
  // import isNil from 'lodash/isNil';
  // import isObject from 'lodash/isObject';
  import { isNil, isObject } from 'lodash';

  export default {
    name: 'LiveTextBlock',
    components: {
      ResponsiveImage,
      ShareBtn,
      SubscriptionLabel,
    },
    mixins: [FavoriteMixin, AuthVariableMixin],

    props: {
      event: {
        default: null,
        validator: prop => {
          return isNil(prop) || isObject(prop);
        },
      },
      channel: {
        type: Object,
        required: true,
      },
    },
    computed: {
      titleChannel() {
        return this.channel.title;
      },
      iconChannel() {
        return this.channel.poster || this.channel.backdrop;
      },
      titleEvent() {
        return this.event ? this.event.title : null;
      },
      timeEvent() {
        if (!this.event) {
          return null;
        }
        let format = 'HH:mm';
        let utc = false;
        let startTime = formatDate(this.event.startDateTime, format, utc);
        let endTime = formatDate(this.event.endDateTime, format, utc);
        return `${startTime}-${endTime}`;
      },

      mediaId() {
        return this.channel.id;
      },
      itype() {
        return this.channel.itype;
      },
      // TODO: ft new-live-design
      isNewLiveDesign() {
        return this.isActiveFeature('new-live-design');
      },
      // TODO: ft block-subscribe-btn
      isSubscribeBtn() {
        return this.isActiveFeature('block-subscribe-btn');
      },
    },
    watch: {
      'channel.id': function(el) {
        // this.getAllTagsForVideo();
      },
    },
    created() {
      // this.getAllTagsForVideo();
    },
  };
</script>


<style lang="scss">


	.live-text {
		&-poster {
			flex-basis: 72px;
			height: 72px;
			max-width: 72px;
		}

		&-image {
			// max-width: 48px;
			// max-height: 48px;

			&-main {
				flex-basis: 72px;
				height: 72px;
				margin: auto;
				max-width: 72px;
			}

			// height: auto;
			// width: auto;
			// margin-top: 50%;
			// margin-left: 50%;
			// transform: translate(-50%, -50%);
		}

		&-text {
			font-size: 1em;
			// min-width: 0;
		}

		&-time {
			font-size: 0.8em;
		}
	}

	@include media-breakpoint-up('lg') {
		.live-text {
			&-time {
				font-size: 1em;
			}

			&-image {
				// max-width: 72px;
				// max-height: 72px;

				&-main {
					// max-width: 72px;
					// max-height: 72px;
					// margin: auto;
				}
			}
		}
	}


</style>


