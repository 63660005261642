import formatDate from 'src/filters/formatDate';
import isString from 'lodash/isString';
import { amsClient } from '../../service/ams';

// NOTE: CURRENT_TIMEZONE_OFFSET: for time zone UTC+2:00, '-120' will be returned.
const STATE_CONST = {
  AIS_HARDCODED_TIME_ZONE_OFFSET: 7 * 60 * 60 * 1000,
  CURRENT_TIMEZONE_OFFSET: new Date().getTimezoneOffset() * 60 * 1000,
  NORMALISE_TIME: 5 * 60 * 1000,
  AUTO_REFRESH_EPG_INTERVAL: 1 * 60 * 1000,
  EPG_FUTURE_TIME: 5 * 60 * 1000,
  EPG_FUTURE_BIG_TIME: 24 * 60 * 60 * 1000,
  EPG_FUTURE_BIG_TIMEOUT: 1 * 60 * 1000,
  BIG_NORMALISE_TIME: 4 * 60 * 60 * 1000,
};

const normaliseDate = function (date, normaliseTime = 0) {
  const normaliseDate = date - (date % normaliseTime);
  return (
    normaliseDate
    + STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET
    + STATE_CONST.CURRENT_TIMEZONE_OFFSET
  );
};

const toTimeStamp = function (date, time, timeZoneOffset = 0) {
  const _d = date.split('-');
  const _t = time.split(':');
  return (
    Date.UTC(+_d[0], +_d[1] - 1, +_d[2], +_t[0], +_t[1], +_t[2])
    - timeZoneOffset
  );
};

const state = () => ({
  // epglist: { id: [{item1}, {item2}] },
  epglist: {},
  // activeEpgs: {id: {item}, id2: {item2}},
  activeEpgs: {},
  //
  channels: null,
  activeChannel: null,
  isLoading: true,
  channelsId: [],
  timeInterval: null,
});

const getters = {
  getCurrentEpg(state) {
    return (channel) =>
      // console.log(channel,  state.activeEpgs);
      (state && state.activeEpgs && state.activeEpgs[channel]
        ? state.activeEpgs[channel]
        : null);
  },

  _getChannelsIdStr(state) {
    return state.channels && state.channels.length ? state.channels.map((el) => el.id).join(',') : null;
  },

  _getIntervalId(state) {
    return state.timeInterval;
  },

  getEpgByChannel(state) {
    return (chid) => state.epglist[chid] || [];
  },

  getChannels(state) {
    return state.channels || [];
  },

  getLoading(state) {
    return state.isLoading;
  },

  getActiveChannel(state) {
    return state.activeChannel || null;
  },
};

const mutations = {
  setEpgList(state, items) {
    if (items && items.length) {
      const dt = [];
      items.forEach((el) => {
        el.startDateTime = toTimeStamp(
          el.date,
          el.start,
          STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
        );
        el.endDateTime = toTimeStamp(
          el.date_end,
          el.end,
          STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
        );
        dt[el.parent] = dt[el.parent] || [];
        dt[el.parent].push(el);
      });
      Object.keys(dt).forEach((key) => {
        state.epglist[key] = dt[key].sort((epg1, epg2) => epg1.startDateTime - epg2.startDateTime);
      });
      state.epglist = { ...dt };
    }
  },

  setEpgById(state, { items, id }) {
    items.forEach((el) => {
      el.startDateTime = toTimeStamp(
        el.date,
        el.start,
        STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
      );
      el.endDateTime = toTimeStamp(
        el.date_end,
        el.end,
        STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
      );
    });
    const dt = state.epglist;
    dt[id] = items.sort((epg1, epg2) => epg1.startDateTime - epg2.startDateTime);
    state.epglist = { ...dt };
  },

  setActiveEpg(state) {
    const list = Object.keys(state.epglist);
    const now = Date.now();
    const data = {};
    for (let keyIter = 0; keyIter < list.length; keyIter++) {
      for (let i = 0; i < state.epglist[list[keyIter]].length; i++) {
        const item = state.epglist[list[keyIter]][i];
        if (item.startDateTime <= now && item.endDateTime >= now) {
          data[list[keyIter]] = item;
        } else if (item.startDateTime > now) {
          break;
        }
      }
    }
    state.activeEpgs = { ...data };
  },

  setIntervalId(state, value) {
    state.timeInterval = value;
  },

  setLoading(state, value) {
    state.isLoading = value;
  },

  setChannels(state, items) {
    state.channels = items && items.length ? Object.assign([], items) : null;
  },

  setActiveChannel(state, item) {
    const key = item ? (isString(item) ? item : item.id) : item === 0 ? 0 : null;
    const index = state?.channels.findIndex((el) => (el.id === key || key === 0));
    state.activeChannel = index > -1 ? ({ ...state.channels[index] }) : null;
  },
};

const actions = {
  getEpgList({ dispatch, getters, commit }) {
    const items = getters._getChannelsIdStr;
    const dateStart = normaliseDate(Date.now(), STATE_CONST.NORMALISE_TIME);
    const dateEnd = normaliseDate(Date.now() + STATE_CONST.EPG_FUTURE_TIME, STATE_CONST.NORMALISE_TIME);

    const url = `/epg/?start_date=${formatDate(
      dateStart,
      'yyyy-MM-dd',
    )}&end_date=${formatDate(dateEnd, 'yyyy-MM-dd')}&start_time=${formatDate(
      dateStart,
      'HH:mm:ss',
    )}&end_time=${formatDate(dateEnd, 'HH:mm:ss')}&items=${items}`;

    return amsClient.callAms(url, { cache: false })
      .then((data) => {
        if (data && data.items) {
          commit('setEpgList', data.items);
          commit('setActiveEpg');
          dispatch('enableAutoRefreshEpg');
          setTimeout(() => {
            dispatch('getEpgListForOneDay');
          }, STATE_CONST.EPG_FUTURE_BIG_TIMEOUT);
          return data.items;
        }
        return [];
      });
  },
  getEpgListForOneDay({ getters, commit }) {
    const items = getters._getChannelsIdStr;
    const dateStart = normaliseDate(Date.now(), STATE_CONST.BIG_NORMALISE_TIME);
    const dateEnd = normaliseDate(Date.now() + STATE_CONST.EPG_FUTURE_BIG_TIME, STATE_CONST.BIG_NORMALISE_TIME);

    const url = `/epg/?start_date=${formatDate(
      dateStart,
      'yyyy-MM-dd',
    )}&end_date=${formatDate(dateEnd, 'yyyy-MM-dd')}&start_time=${formatDate(
      dateStart,
      'HH:mm:ss',
    )}&end_time=${formatDate(dateEnd, 'HH:mm:ss')}&items=${items}`;

    // FIXME: delete timeout is user lost the page;
    if (items) {
      return amsClient.callAms(url, { cache: false })
        .then((data) => {
          if (data && data.items) {
            commit('setEpgList', data.items);
            return data.items;
          }
          return [];
        });
    }
    console.warn('You lost the channelpage');
  },
  enableAutoRefreshEpg({ commit, getters, dispatch }) {
    if (!getters._getIntervalId) {
      const intervalID = setInterval(() => {
        commit('setActiveEpg');
      }, STATE_CONST.AUTO_REFRESH_EPG_INTERVAL);
      commit('setIntervalId', intervalID);
    }
  },

  disableAutoRefreshEpg({ getters, commit }) {
    if (getters._getIntervalId) {
      clearInterval(getters._getIntervalId);
      commit('setIntervalId', null);
      commit('setChannels', null);
    }
  },

  getEpgById({ commit }, { id }) {
    return amsClient.callAms(`/epg/?items=${id}`, { cache: false })
      .then((data) => {
        if (data && data.items) {
          commit('setEpgById', { items: data.items, id });
          commit('setActiveEpg');
          return data.items;
        }
        return [];
      });
  },

  getRemoteChannels({ commit, dispatch }) {
    commit('setLoading', true);
    return amsClient.callAms('/get_channels/', { cache: false }).then((data) => {
      if (!data.error) {
        const { items } = data;
        items.map((item) => {
          item.pss = {
            canPlayPremium: amsClient.canPlayPremium(item),
            canPlayFreemium: amsClient.canPlayFreemium(item),
            isPremium: amsClient.isContentPremium(item),
            isFreemium: amsClient.isContentFreemium(item),
            canPlay: !item.not_free || (amsClient.isContentFreemium(item) && amsClient.canPlayFreemium(item)) || (amsClient.isContentPremium(item) && amsClient.canPlayPremium(item)),
          };
        });
        commit('setChannels', items);
        dispatch('getEpgList');
      }
      return data;
    }).finally(() => {
      commit('setLoading', false);
    });
    // return this._vm
    //   .$longCache({
    //     url: url,
    //   }).then(response => {
    //       if (!response.data.error) {
    //         commit(`setChannels`, response.data.items);
    //         dispatch(`getEpgList`);
    //       }
    //       return response.data;
    //   }).finally(() => {
    //     commit(`setLoading`, false);
    //   });
  },
  onSync({
    state, dispatch, getters, commit,
  }, data) {
    /** to refresh only if there is any changes */
    if (Object.keys(data.addedProducts).length || Object.keys(data.removedProducts).length) {
      dispatch('refreshChannels');
    }
  },
  refreshChannels({
    state, dispatch, getters, commit,
  }, data) {
    return amsClient.callAms('/get_channels/', { cache: false }).then((data) => {
      if (!data.error) {
        const { items } = data;
        commit('setChannels', items);
        dispatch('getEpgList');
      }
      return data;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
