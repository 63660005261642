<template>
  <div class="epg-item d-flex flex-row align-items-center mw-100 w-100">
    <!-- <div class="row"> -->
    <div class="epg-item-schedule px-3">
      {{ item.startDateTime | formatDate('HH:mm', false) }}
    </div>
    <div
      class="epg-item-event-name pr-3 one-line-truncate-flex-fix "
      :title="title"
    >
      <span class="d-inline-block text-truncate mw-100"> {{ title }} </span>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
  import TrimEl from 'src/components/common/trim-el.vue';
  import contentLanguage from 'src/filters/contentLanguage.js';

  export default {
    name: 'ChannelEpgItem',
    components: {
      TrimEl,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      title() {
        return contentLanguage(this.item, 'title');
      },
    },
  };
</script>

<style lang="scss">
	.epg-item {
		// padding: 0px 10px;

		&-schedule,
		&-event-name {
			// align-self: center;
			font-size: 0.8em;
		}
	}
</style>

